import { useLocalStorage, watchOnce } from '@vueuse/core'
import { defineStore } from 'pinia'
import { findLast } from 'lodash'
import { type ServiceRecordForQuery, type ZoneInfo, getAllServiceRecords, getServiceInfo, heartbeat, heartbeatAuthed, queryWaitingInfo, requestWelcome } from '~/api'
import { useServiceRecord } from '~/composables/service'
import { createLocalCustomMsg, queryHistory } from '~/utils/nim'
import useAuthStore from '~/stores/auth'
import { DAY, ROBOT_ACCOUNT, SINGLE_PAGE_MSG_MAX, VISITOR_OFFLINE_MSG_ENABLED } from '~/constants'
import { ClientMessageType, isServiceClosed, queryIdb, updateIdb } from '~/utils/tools'

export const useChat = defineStore('chat', () => {
  const imToken = ref('')
  const imAccount = ref('')
  const isVisitor = ref(false)
  const connected = ref(false)
  const connectDone = ref(false)
  watchOnce(connected, (v) => {
    if (v)
      connectDone.value = true
  })
  const reconnecting = ref(false)

  const messages = ref<Message[]>([])
  const currentService = useServiceRecord()

  const settings = ref<ZoneInfo>()
  const authStore = useAuthStore()
  const canChat = computed(() => settings.value?.allowVisitor === 'YES' || authStore.authed)
  const historyDone = ref(false)
  const historyLoading = ref(false)
  const historyExhaust = ref(false)
  const historyIndex = ref(0)

  function handleMessage(msg: Message) {
    if (msg.custom && !msg.customDecoded)
      msg.customDecoded = JSON.parse(msg.custom)
    else if (msg.contentDecoded?.type === 'evaluateInvite' && msg.contentDecoded?.content?.serviceRecordId)
      msg.customDecoded = { serviceRecordId: msg.contentDecoded?.content?.serviceRecordId }

    if (msg.type === 'custom' && msg.content) {
      if (!msg.contentDecoded)
        msg.contentDecoded = JSON.parse(msg.content)
      if (msg.contentDecoded?.type === 'welcome' || msg.contentDecoded?.type === 'questionReply')
        msg.fromNick = settings.value?.robotNickname || '客服机器人'
    }

    if (msg.from === ROBOT_ACCOUNT)
      msg.fromNick = settings.value?.robotNickname || '客服机器人'

    return msg
  }

  function insertMsg(list: Message[], msg: Message) {
    if (list.length === 0)
      return [msg]
    let lowerBound
    for (let i = list.length - 1; i >= 0; --i) {
      const item = list[i]
      if (item.time <= msg.time) {
        if (item.idServer && msg.idServer && item.idServer === msg.idServer)
          return list
        lowerBound = i
        break
      }
    }
    if (lowerBound)
      return [...list.slice(0, lowerBound + 1), msg, ...list.slice(lowerBound + 1)]
    else
      return [msg, ...list]
  }

  function deleteMessage(idServer: string) {
    messages.value = toRaw(messages.value).filter(x => x.idServer !== idServer)
    if (isVisitor.value)
      updateDb()
  }

  function updateDb() {
    updateIdb(toRaw(messages.value).filter(x => !x.isLocal))
  }

  function addMessage(msg: Message, type: ClientMessageType) {
    msg = handleMessage(msg)
    if (type === ClientMessageType.IN_OFFLINE) {
      const SERVICE_MISMATCH_OR_EMPTY = !currentService.value.serviceRecordId || msg.customDecoded?.serviceRecordId !== currentService.value.serviceRecordId
      if (SERVICE_MISMATCH_OR_EMPTY && isVisitor.value && !useLocalStorage(VISITOR_OFFLINE_MSG_ENABLED, false))
        return msg
    }
    else if (type === ClientMessageType.IN_REALTIME) {
      // get service record from msg
      if (msg.customDecoded?.serviceRecordId && !currentService.value.serviceRecordId) {
        currentService.value = {
          ...msg.customDecoded,
          status: currentService.value.status,
        }
      }
    }
    if (type === ClientMessageType.IN_OFFLINE)
      messages.value = insertMsg(toRaw(messages.value), msg)
    else
      messages.value.push(msg)

    if (isVisitor.value && !msg.isLocal && type !== ClientMessageType.IN_FAKE)
      updateDb()
    return findLast(messages.value, x => x.idClient === msg.idClient)
  }

  async function addSystemMessage(msg: SystemNotice) {
    const parsed = msg.content ? JSON.parse(msg.content) : undefined

    if (parsed.type === 'assignSeat' || parsed.type === 'assignNightSeat') {
      currentService.value = {
        seatNickname: parsed.content.seatNickname,
        seatUserId: parsed.content.seatUserId,
        serviceRecordId: parsed.content.serviceRecordId,
        status: parsed.content.serviceRecordId === currentService.value.serviceRecordId && currentService.value.status !== 'IN_SERVICE' ? 'INIT' : currentService.value.status,
      }
      const waiting = findLast(messages.value, x => x.type === 'custom' && x.contentDecoded?.type === 'waiting')
      if (waiting) {
        if (waiting.contentDecoded!.content.seatName) {
          if (waiting.contentDecoded?.content.seatName !== parsed.content.seatNickname) {
            addMessage(
              createLocalCustomMsg('waiting', { count: 0, seatName: parsed.content.seatNickname }, currentService.value),
              ClientMessageType.IN_FAKE,
            )
          }
        }
        else {
          waiting.contentDecoded!.content = {
            count: 0,
            seatName: parsed.content.seatNickname,
          }
          waiting.customDecoded = parsed.content
        }
      }
      else {
        addMessage(
          createLocalCustomMsg('waiting', {
            count: 0,
            seatName: parsed.content.seatNickname,
            night: parsed.type === 'assignNightSeat',
          }, currentService.value),
          ClientMessageType.IN_FAKE,
        )
      }
    }
    else if (parsed.type === 'inService') {
      currentService.value = {
        seatNickname: parsed.content.seatNickname,
        seatUserId: parsed.content.seatUserId,
        serviceRecordId: parsed.content.serviceRecordId,
        status: 'IN_SERVICE',
      }
    }
    else if (parsed.type === 'closeService') {
      if (currentService.value.serviceRecordId)
        currentService.value.status = 'TIMEOUT_CLOSE'
    }
    else if (parsed.type === 'finishService') {
      currentService.value = {
        seatNickname: undefined,
        seatUserId: undefined,
        serviceRecordId: undefined,
      }
    }
  }

  const records = ref<ServiceRecordForQuery[]>([])

  watchOnce(connected, async (v) => {
    if (v) {
      if (!isVisitor.value) {
        await loadServiceRecords().catch((err) => {
          console.error(err)
        })
      }
      await loadHistory().catch((err) => {
        console.error(err)
      })
      // pull the robot welcome if there isn't one
      if (!messages.value.find(x => x.type === 'custom' && x.contentDecoded?.type === 'welcome')) {
        await requestWelcome().catch((err) => {
          console.error(err)
        })
      }
      await processCurrentService().catch((err) => {
        console.error(err)
      })
      historyDone.value = true

      maintainConnection()
    }
  })

  async function loadServiceRecords() {
    records.value = await getAllServiceRecords(isVisitor.value)
  }

  function createGroups(records: ServiceRecordForQuery[], start: number, end: number) {
    return records.map((record) => {
      return {
        ...record,
        serviceTimeList: record.serviceTimeList.filter((item) => {
          return !(item.startTime > end || item.endTime < start)
        }).map((item) => {
          return { startTime: Math.max(start, item.startTime), endTime: Math.min(end, item.endTime) }
        }),
      }
    }).filter(record => record.serviceTimeList.length > 0)
  }

  // - for authed user(db enabled)
  // first time only query local
  // if local is empty, then query remote, until there are no service records remain
  // second time query local + remote
  // - for visitor(db disabled, use custom idb-keyval, keyed by zoneCode)
  async function loadHistory(includeRemote = false) {
    if (isVisitor.value) {
      const history = await queryIdb()
      messages.value = [...history, ...toRaw(messages.value)]
      historyExhaust.value = true
    }
    else {
      const end = Date.now() - historyIndex.value * DAY
      const start = end - 30 * DAY
      const last = messages.value.find(x => x.idServer)
      const groups = !includeRemote
        ? []
        : (() => {
            const result = createGroups(records.value, start, end)
            result.push({
              to: ROBOT_ACCOUNT,
              imAccount: imAccount.value,
              imToken: imToken.value,
              serviceTimeList: [{ startTime: start, endTime: end }],
            })
            return result
          })()

      const history = await queryHistory([start, end], groups, last?.idServer, last?.time)
      if (history.length === 0 && includeRemote && groups.filter(x => x.to !== ROBOT_ACCOUNT).length === 0)
        historyExhaust.value = true
      else if (includeRemote)
        historyIndex.value += 30

      if (history.length)
        messages.value = [...history.map(handleMessage), ...toRaw(messages.value)]

      if (messages.value.length < SINGLE_PAGE_MSG_MAX && !historyExhaust.value)
        return loadHistory(true)
    }
  }

  async function loadMoreHistory() {
    if (historyExhaust.value || historyLoading.value)
      return
    historyLoading.value = true
    await loadHistory(true)
    historyLoading.value = false
  }

  async function maintainConnection() {
    if (connected.value)
      await (isVisitor.value ? heartbeat() : heartbeatAuthed())
    setTimeout(() => {
      maintainConnection()
    }, 60 * 1000)
  }

  async function processCurrentService() {
    const [err, res] = await queryWaitingInfo()
    if (err)
      return

    const { beforeCount, ...others } = res.data
    if (others.serviceRecordId) {
      currentService.value = others

      {
        const [err, res] = await getServiceInfo()
        if (err)
          return
        currentService.value.status = res.data.status
        if (res.data.status === 'IN_SERVICE' || res.data.status === 'INIT' || isServiceClosed(res.data.status)) {
          addMessage(
            createLocalCustomMsg('waiting', { count: 0, seatName: res.data.seatNickname, night: res.data.seatMode === 'NIGHT' }, currentService.value),
            ClientMessageType.IN_FAKE,
          )
        }
        else if (res.data.status === 'FINISH') {
          currentService.value = {
            seatNickname: undefined,
            seatUserId: undefined,
            serviceRecordId: undefined,
          }
        }
      }
    }
    else if (beforeCount != null) {
      addMessage(
        createLocalCustomMsg('waiting', { count: beforeCount }),
        ClientMessageType.IN_FAKE,
      )
      currentService.value = {
        seatNickname: undefined,
        seatUserId: undefined,
        serviceRecordId: undefined,
      }
    }
    else {
      currentService.value = {
        seatNickname: undefined,
        seatUserId: undefined,
        serviceRecordId: undefined,
      }
    }
  }

  return {
    imToken,
    imAccount,
    isVisitor,
    connected,
    connectDone,
    reconnecting,
    messages,
    addMessage,
    deleteMessage,
    addSystemMessage,
    currentService,
    settings,
    canChat,
    historyDone,
    historyLoading,
    historyExhaust,
    loadMoreHistory,
    updateDb,
  }
})
