import { useSessionStorage } from '@vueuse/core'
import * as db from 'idb-keyval'
import { hideLoading, showLoading } from './loading'
import { ServerError } from './request'
import * as api from '~/api'
import { APPID, WXAUTHURL } from '~/constants'

export {
  showLoading,
  hideLoading,
}

export function showAlert(payload = {}) {
  return promisify(uni.showModal)({
    title: '提示',
    showCancel: false,
    ...(typeof payload === 'string'
      ? {
          content: payload,
        }
      : payload),
  })
}

export function showConfirm(payload = {}) {
  return promisify(uni.showModal)({
    title: '提示',
    showCancel: true,
    ...(typeof payload === 'string'
      ? {
          content: payload,
        }
      : payload),
  }) as Promise<{ confirm: boolean }>
}

export function showToast(payload = {}) {
  uni.showToast({
    icon: 'none',
    ...(typeof payload === 'string'
      ? {
          title: payload,
        }
      : payload),
  })
}

/**
 * 将 success fail complete 的接口转化为 promise 形式
 * @template T
 * @param {T} api
 * @return {(...x: Parameters<T>) => Promise} promisified api
 */
export function promisify(api: any, ctx = uni) {
  return function promisified(options: any, ...others: any) {
    return new Promise((resolve, reject) => {
      const oldApi = api.bind(ctx)

      oldApi({
        ...options,
        success: resolve,
        fail: reject,
      }, ...others)
    })
  }
}

export function omitEmptyValues(obj: Record<string, unknown>): Record<string, Exclude<unknown, null | undefined>> {
  return Object.keys(obj)
    .map(k => [k, obj[k]] as const)
    .filter(([_k, v]) => v != null)
    .reduce((acc, [k, v]) => {
      return {
        ...acc,
        [k]: v,
      }
    }, {})
}

export function createUrl(url: string, extraParams?: Record<string, string | number | null | undefined>, hash?: string) {
  const u = new URL(url)
  for (const key in extraParams) {
    if (extraParams[key] != null)
      u.searchParams.set(key, extraParams[key]?.toString() || '')
  }
  if (hash)
    u.hash = `#${hash}`.replace('##', '#')
  return u.toString()
}

export function createPath(url: string, extraParams?: Record<string, string | number | null | undefined>) {
  const u = new URL(`${location.origin}${url}`)
  for (const key in extraParams) {
    if (extraParams[key] != null)
      u.searchParams.set(key, extraParams[key]?.toString() || '')
  }
  return u.pathname + u.search
}

export function getCurrentPath() {
  const url = new URL(location.href)
  return url.pathname
}

export function getCurrentPathWithSearch() {
  const url = new URL(location.href)
  return url.pathname + url.search
}

export function getCurrentUrl() {
  return location.href
}

export function last<T>(arr: T[]) {
  return arr[arr.length - 1]
}

export function createControlledPromise() {
  let resolve: (value: unknown) => void
  const callback = () => {
    resolve?.(null)
  }
  const promise = new Promise((_resolve) => {
    resolve = _resolve
  })

  return [promise, callback] as const
}

export async function getAppId(forceFromZone = false) {
  const appId = useSessionStorage(APPID, '')
  if (!forceFromZone && appId.value) {
    return appId.value
  }
  else {
    const z = useZone()
    const [_err, res] = await api.getAppIdByZoneCode(z.value.zoneCode!)
    return res?.data
  }
}

export function setAppId(v: string) {
  const appId = useSessionStorage(APPID, '', { flush: 'sync' })
  appId.value = v
}

export function getMaskPhone(phone?: string) {
  if (!phone)
    return phone
  return `${phone.slice(0, 3)}****${phone.slice(-4)}`
}

export function isInWeixinBrowser() {
  return window.navigator.userAgent.toLowerCase().includes('micromessenger')
}

export function withAuthErrorHandled(err: Error, callback: () => void) {
  if (!(err instanceof ServerError && err.type === 'Authentication'))
    callback()
}

export type Platform = 'iOS' | 'Android' | 'Wap'
export function getPlatform(): Platform {
  const userAgent = navigator.userAgent

  if (/android/i.test(userAgent))
    return 'Android'

  if (/iPad|iPhone|iPod/.test(userAgent))
    return 'iOS'

  return 'Wap'
}

/**
 * initiate a wx auth request
 */
export function initiateWxAuth(redirectUrl: string, appId: string) {
  const rr = new URL(redirectUrl)
  rr.searchParams.delete('state')
  rr.searchParams.delete('appid')

  const url = createUrl(WXAUTHURL, {
    appid: appId,
    redirect_uri: rr.toString(),
    response_type: 'code',
    scope: 'snsapi_base',
    state: appId,
    component_appid: import.meta.env.VITE_APP_COMPONENT_APPID,
  }, '#wechat_redirect')

  location.href = url

  // prevent anything else from executing when in production
  return import.meta.env.DEV
    ? new Promise(resolve => setTimeout(resolve, 1500))
    : new Promise((resolve) => {
      if (false)
        resolve(undefined)
    })
}

export function error(msg?: string) {
  return showAlert(msg || '出错了')
}

export function isServiceClosed(status: ServiceRecordStatus) {
  return status === 'SEAT_CLOSE' || status === 'CUSTOMER_CLOSE' || status === 'TIMEOUT_CLOSE'
}

export function formatVideoDuration(ms: number) {
  const s = Math.round(ms / 1000)
  const minute = Math.floor(s / 60)
  const hour = Math.floor(minute / 60)
  const groups = [hour, minute % 60, s % 60]
  if (!groups[0])
    groups.unshift()
  return groups.map(x => String(x).padStart(2, '0')).join(':')
}

export function downloadUrl(url: string, name: string) {
  const a = document.createElement('a')
  a.href = url
  a.style.cssText = 'position: absolute; width: 0; height: 0; top: 0; left: 0; z-index: -1; opacity: 0.0001;'
  a.download = name
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    a.remove()
  })
}

export enum ClientMessageType {
  IN_OFFLINE,
  IN_REALTIME,
  IN_FAKE,
  OUT,
}

export async function queryIdb() {
  const zoneCode = useZone().value.zoneCode
  if (!zoneCode)
    return []
  try {
    const msgs = await db.get(zoneCode)
    return msgs || []
  }
  catch {
    return []
  }
}

export async function updateIdb(messages: Message[]) {
  const zoneCode = useZone().value.zoneCode
  if (!zoneCode)
    return
  try {
    await db.set(zoneCode, messages)
  }
  catch {
    // do nothing
  }
}
