export const USERINFO = 'USERINFO'

export const WXAUTHURL = 'https://open.weixin.qq.com/connect/oauth2/authorize'
export const APPID = 'APPID'
export const ZONE = 'ZONE'
export const VISITOR_CODE = 'VISITOR_CODE'
export const VISITOR_CODE_REFRESH_TIME = 'VISITOR_CODE_REFRESH_TIME'
export const VISITOR_OFFLINE_MSG_ENABLED = 'VISITOR_OFFLINE_MSG_ENABLED'
export const SERVICE_RECORD = 'SERVICE_RECORD'
export const BUSINESS_LINE = 2099
export const ROBOT_ACCOUNT = 'robot'
export const DAY = 24 * 60 * 60 * 1000
export const SINGLE_PAGE_MSG_MAX = 20

// third
export const LAST_JM_TOKEN = 'LAST_JM_TOKEN'
